<template>
	<div>
		<searchCard>
			<div class="flex">
				<div class="w-4/12 px-2">
					<h6>신청일</h6>
					<div class="flex">
						<b-form-radio class="w-20" v-model="opt.period" name="periodRadio" value="">
							<span class="mr-2">전체</span>
						</b-form-radio>
						<b-form-radio v-model="opt.period" name="periodRadio" value="period" />
						<div class="flex -mt-1.5">
							<datePicker placeholder="from" model="opt.startDate" />
							<span class="p-2 lh-6">~</span>
							<datePicker placeholder="to" model="opt.endDate" />
						</div>
					</div>
				</div>
				<div class="w-2/12 px-2">
					<h6>승인여부</h6>
					<div class="flex">
						<b-form-radio
							v-for="v in [
								{ label: '대기', value: 'P' },
								{ label: '승인', value: 'Y' },
								{ label: '거절', value: 'R' },
							]"
							class="w-20"
							v-model="opt.saveStatus"
							name="statusRadio"
							:value="v.value"
						>
							<span class="mr-2">{{ v.label }}</span>
						</b-form-radio>
					</div>
				</div>
				<div class="w-6/12">
					<h6>검색어</h6>
					<div class="flex">
						<vSelect class="w-80" v-model="opt.searchKeyName" :clearable="false" :options="searchKeyOpts" />
						<b-form-input
							class="mx-4"
							v-model.trim="opt.searchKeyword"
							name="keyword"
							placeholder="검색어"
							@keyup.enter="search"
						/>
						<b-button class="w-40 px-6" @click="search">검색</b-button>
					</div>
				</div>
			</div>
		</searchCard>

		<b-card>
			<b-button class="absolute top-0 right-0" @click="rejectAll">일괄 거절</b-button>
			<tb class="mt-12" :inf="inf" :res="res" checkBoxKey="hottId" />
		</b-card>
		<hottistPopup
			:contentCategoryOpts="contentCategoryOpts"
			:contentQualityOpts="contentQualityOpts"
			:hottistTypeOpts="hottistTypeOpts"
			:pageKey="pageKey"
			:pr="ths"
			:userTypeOpts="userTypeOpts"
			cName="hottistPopup"
		/>
	</div>
</template>

<script>
import hottistPopup from 'pages/hottistPopup'

const now = new Date(),
	searchKeyOpts = [
		{ label: '통합검색', value: '' },
		{ label: '핫트 아이디', value: 'hottId' },
		{ label: '이름', value: 'memberName' },
		{ label: '닉네임', value: 'nickName' },
		{ label: '연락처', value: 'phone' },
	],
	defOpt = {
		period: '',
		saveStatus: 'P',
		startDate: getMonth(now, -1),
		endDate: now,
		searchKeyName: searchKeyOpts[0],
		searchKeyword: '',
	}

export default {
	components: { hottistPopup },
	data() {
		return {
			userTypeOpts: [],
			searchKeyOpts,
			opt: { ...defOpt },
			defOpt,

			info: [
				{ model: 'checkAll' },
				{ title: 'No.', model: 'idx', size: 2 },
				{ title: '승인여부', key: 'saveStatus', size: 2 },
				{
					title: '핫트아이디',
					key: 'hottId',
					size: 2,
				},
				{
					title: '이름',
					model: 'button',
					size: 2,
					text: item => item.name,
					bVariant: 'no',
					class: 'text-primary',
					func: item => this.hottistPopup.open(item, true, true),
				},
				{ title: '닉네임', key: 'nickName', size: 2 },
				{ title: '연락처', key: 'phone', size: 3 },
				{ title: '홍보채널1', key: 'snsLink', size: 4, model: 'url', notCenter: 1 },
				{ title: '홍보채널2', key: 'snsLink2', size: 4, model: 'url', notCenter: 1 },
				{ title: '홍보채널3', key: 'snsLink3', size: 4, model: 'url', notCenter: 1 },
				{ title: '신청일자', key: 'hottDealReqDt', size: 3, sortable: 1 },
				{ title: '최근거절일자', key: 'hottDealRejectDt', size: 3, sortable: 1 },
				{
					title: '거절',
					model: 'button',
					size: 2,
					text: '거절',
					bVariant: 'info',
					func: this.reject,
					saveStatusCondition: 'Y',
				},
				{
					title: '승인',
					model: 'button',
					size: 2,
					text: '승인',
					func: item => this.hottistPopup.open(item),
					saveStatusCondition: 'R',
				},
				{ title: '메모', model: 'memoBtn', domain: 'MEMBER', size: 1 },
				{ title: '변경내역', model: 'memoBtn', domain: 'HOTTIST_HISTORY', readOnly: true, size: 2 },
			],
			lastOpt: {},
			res: {},

			hottistTypeOpts: [],
			contentQualityOpts: [],
			contentCategoryOpts: [],
			pageKey: 'hottistExamList',
		}
	},
	methods: {
		search() {
			const opt = this.opt,
				searchKey = getDropdownValue(opt.searchKeyName),
				searchVal = opt.searchKeyword,
				error = [],
				data = {
					saveStatus: opt.saveStatus,
					userType: opt.saveStatus != 'Y' ? 'L' : 'A',
					orderBy: getOrder('hottDealReqDt'),
					paging: getPaging(),
					isExcel: false,
				}

			if (opt.period) {
				data.startDate = opt.startDate.get_time('d', 0, '')
				data.endDate = opt.endDate.get_time('d', 0, '')
				data.dateType = 'reqDt'
			}

			if (searchVal) data.searchKey = getSearchKey(searchKey ? searchKey : searchKeyOpts, searchVal)

			if (error.length) alert.w(error)
			else {
				this.lastOpt = data
				this.changePage()
			}
		},
		changePage(page = 1) {
			this.lastOpt.paging.pageNo = page - 1

			postApi('api/backoffice/hottistSelect/hottistList', this.lastOpt).then(res => {
				res.list = res.list.map(v => {
					v.memberName = v.name
					v.memberSeq = v.seq
					return v
				})
				this.res = res
			})
		},
		reject(list) {
			if (!Array.isArray(list)) list = [list]
			return this.hottistStatusChange(list, true)
		},
		rejectAll() {
			const list = this.tb.getChecked()
			if (list.length) this.reject(list).then(() => this.tb.clearChecked())
			else alert.w('핫티스트 심사를 거절할 멤버를 선택해주세요')
		},
		hottistStatusChange(list, isReject = false) {
			const defaultData = isReject ? { saveStatus: 'H', userType: 'L' } : { saveStatus: 'Y', userType: 'A' }
			return putApi(
				'api/backoffice/hottistHandle/updateHottistExam',
				list.map(v => {
					return { ...defaultData, memberSeq: v.seq }
				})
			).then(() => {
				const state = isReject ? '거절' : '승인'
				alert.s(`핫티스트 ${state}에 성공했습니다`)
				reload(this)
			})
		},
	},
	computed: {
		inf() {
			return this.info
			const accessibleSpecailFunc = this.$acl.check('adminOnly') && this.lastOpt.saveStatus == 'P'
			return this.info.filter(v => {
				if (!v.saveStatusCondition) return true
				//조건이 없으면 기본적으로 보임

				const saveStatus = this.lastOpt.saveStatus
				if (saveStatus == 'P') return true
				//대기라면 버튼 둘 다 보임

				if (this.$acl.check('adminOnly') && saveStatus == v.saveStatusCondition) return true
				//그게 아니라면 관리자면서 saveStatusCondition 조건에 맞을 때 보임
				return false
			})
		},
	},
	created() {
		getConfig('user_type, hottist_type, content_quality, content_category', false, false).then(res => {
			this.userTypeOpts = res.user_type
			this.hottistTypeOpts = res.hottist_type
			this.contentQualityOpts = res.content_quality
			this.contentCategoryOpts = res.content_category
		})
		this.search()
	},
}
</script>
